import React, { useState } from 'react';
import {Link} from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import {FaFacebook, FaTwitterSquare, FaLinkedin, FaBars} from 'react-icons/fa';

type NavItemProps = {
  to: string,
  children: React.ReactNode,
  onClick: () => void
}

const NavItem: React.FC<NavItemProps> = ({ to, onClick, children }) => {
  return (
    <li><Link to={to} onClick={onClick} activeClassName="font-bold">{children}</Link></li>
  );
};

export type LayoutProps = {
  children: React.ReactNode,
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNav = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="flex flex-col min-h-screen">
      <header className="p-4 text-center">
        <StaticImage src={'../images/logo.png'} alt={'logo'}/>
        <button className={`sm:hidden block`} onClick={toggleNav}>
          <FaBars />
        </button>
      </header>
      <nav className={`flex text-center justify-center bg-gray-100 border p-4 ${isOpen ? 'block' : 'hidden'} sm:block`}>
        <div className="flex justify-center items-center">
          <ul className={`flex flex-col sm:flex-row gap-6 text-gray-600 uppercase text-sm`}>
            <NavItem to="/" onClick={toggleNav}>Ons aanbod</NavItem>
            <NavItem to="/nieuwbouw-kopen" onClick={toggleNav}>Nieuwbouw kopen</NavItem>
            <NavItem to="/financiering" onClick={toggleNav}>Financiering</NavItem>
            <NavItem to="/over-ons" onClick={toggleNav}>Over ons</NavItem>
            <NavItem to="/eerder-verkochte-projecten" onClick={toggleNav}>Verkochte projecten</NavItem>
            <NavItem to="/contact" onClick={toggleNav}>Contact</NavItem>
          </ul>
        </div>
      </nav>
      <main className="flex-grow">
        {children}
      </main>
      <footer className="bg-primary-light text-white text-center p-2">
        <div className="mt-4">
          <a href="/privacyverklaring.pdf" className="text-sm hover:text-gray-50">Privacyverklaring</a>
          {' | '}
          <a href="/Algemene Consumentenvoorwaarden  1 september 2018.pdf" className="text-sm hover:text-gray-50">Privacyverklaring</a>
          {' | '}
          <Link to="/disclaimer" className="text-sm hover:text-gray-50">Disclaimer</Link>
        </div>

        {/* Social media icons */}
        <div className="flex justify-center mt-2">
          <a href="https://www.facebook.com/DNAmakelaars/" target="_blank" rel="noreferrer" className="mx-2">
            <FaFacebook size={24}/>
          </a>
          <a href="https://www.facebook.com/pages/category/Real-Estate-Agent/DNA-Makelaars-199869616697596/" target="_blank" rel="noreferrer" className="mx-2">
            <FaTwitterSquare size={24}/>
          </a>
        </div>
      </footer>
    </div>
  );
};

export default Layout;